import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    // UncontrolledCarousel,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import AutoCompleteCustom from '../../components/landing/AutoCompleteCustom';
import {
    handleSearchKayak,
    saveLog,
    SET_PICKUP_SEARCH_KAYAK_RESULT,
    SET_DROP_SERACH_KAYAK_RESULT,
} from '../../actions';
import { getDateString, getTimeString, getOffsetDate } from '../../utils/helper';
import { toast } from 'react-toastify';
import { times, /*cars*/ } from './data';
import CityIcon from '../../assets/img/city_icon.svg';
import AirportIcon from '../../assets/img/airplane_icon.svg';
import TrainIcon from '../../assets/img/train_icon.svg';


import Papa from "papaparse";

const SearchForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const search = useLocation().search;

    var ppl = new URLSearchParams(search).get('ppl');
    var wtc = new URLSearchParams(search).get('wtc');
    var hv = new URLSearchParams(search).get('hv');
    var mv = new URLSearchParams(search).get('mv');
    
    var ug = new URLSearchParams(search).get('ug');
    var ul = new URLSearchParams(search).get('ul');
    var ft = new URLSearchParams(search).get('ft');
    var bt = new URLSearchParams(search).get('bt');

   ;


    var scl = "";
    var geo = "";
    var accl = "";
    var ppl = "";

    if(hv){
        hv = hv;
    } else {
        hv = "1";
    }
    if(mv){
        mv = mv;
        if(mv == 1){
            mv = ";map";
        }
        else {
            mv = "";
        }
    } else {
        mv = "";
    }


    const [ gclid, setGclid ] = useState('');
    const [ l1, setL1 ] = useState('');
    const [ l2, setL2 ] = useState('');
    const [ keyword, setKeyword ] = useState('');

    const pickupCities = useSelector(state => state.search.pickupKayak);
    const dropCities = useSelector(state => state.search.dropKayak);


    const [ showDrop, setShowDrop ] = useState(false);
    const [ pickupSearchKey, setPickupSearchKey ] = useState('');
    const [ pickupVal, setPickupVal ] = useState('');
    const [ pickupCity, setPickupCity ] = useState('');
    const [ pickupCode, setPickupCode ] = useState('');
    const [ pickupCtid, setPickupCtid ] = useState('');
    const [ pickupCountry, setPickupCountry ] = useState('');
    const [ autoCompleteDataPickup, setAutoCompleteDataPickup ] = useState([]);

    const [ dropSearchKey, setDropSearchKey ] = useState('');
    const [ dropVal, setDropVal ] = useState('');
    const [ dropCity, setDropCity ] = useState('');
    const [ dropCode, setDropCode ] = useState('');
    const [ dropCtid, setDropCtid ] = useState('');
    const [ dropCountry, setDropCountry ] = useState('');
    const [ autoCompleteDataDrop, setAutoCompleteDataDrop ] = useState([]);

    const [ pickupDate, setPickupDate ] = useState(getDateString(getOffsetDate(new Date(), 7)));
    const [ pickupTime, setPickupTime ] = useState('Noon');
    const [ dropDate, setDropDate ] = useState(getDateString(getOffsetDate(new Date(), 14)));
    const [ dropTime, setDropTime ] = useState('Noon');

    const [ lang, setLang ] = useState("");
    const [ autocomplete, setAutocomplete ] = useState("");
    const [ geolocation, setGeo ] = useState("");
    const [ siteContentLanguage, setScl ] = useState("");
    const [ backTab, setBackTab ] = useState("");
    const [ frontTab, setFrontTab ] = useState("");
    const [ defaultFrontTab, setDefaultFrontTab ] = useState("");
    const [ defaultBackTab, setDefaultBackTab ] = useState("");

    const [ webSiteTextContent, setWtc ] = useState(wtc);
    const [ hourView, setHv ] = useState(hv);
    const [ mapView, setMap ] = useState(mv);

    const [ userGeo, setUg ] = useState(ug);
    const [ userLanguage, setUl ] = useState(ul);
    

    const [ houView, sethouView ] = useState(true);

    const [ showError, setShowError ] = useState(false);

    // State for fetch the data from excel
    const [data, setData] = useState({});

    const handleDropToggle = e => {
        setShowDrop(e.target.checked);
    }

    const getTime = (currentTime) => {
        let time = ""
        if (currentTime === 'Noon') {
            time = '12:00';
        } else if (currentTime === 'Midnight') {
            time = '00:00';
        } else {
            time = currentTime;
        }

        return time.substring(0, 2)
    }

    // Start code for fetch the data from excel
    useEffect(() => {
        Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vSw9XkLzGvo5vW3D4bpjUr5p2itsxKrRs69GuKS6X907L1llx-oAZ9cn3iBkAFy2S9HmF0JHYXLXITi/pub?output=csv", {
        
          download: true,
          header: true,
          complete: (results) => {
            setData(results.data);
          },
        });
      }, []);
      

      useEffect(() => {
        const movies = Array.from(data);
        let obj = movies.find(o => o.ug == ug && o.ul== ul);

        if(ug && ul && obj) {
 
            setScl(obj?.scl);  
            setGeo(obj?.cc);  //setGeo(obj?.geo);
            setAutocomplete(obj?.lc);  // setAutocomplete(obj?.accl);
            setLang(obj?.scl);
            
          
            if(obj?.backTab?.length > 0) {
                setBackTab(obj[bt]);
                setFrontTab(obj[ft]);
            }
            setDefaultFrontTab(obj?.frontTab);
            setDefaultBackTab(obj?.backTab);
            
        } else {
            let obj = movies.find(o => o.ug == "us" && o.ul== "en");
            
            setScl(obj?.scl);  
            setGeo(obj?.cc);  //setGeo(obj?.geo);
            setAutocomplete(obj?.lc);  // setAutocomplete(obj?.accl);
            setLang(obj?.scl);
            setDefaultFrontTab(obj?.frontTab);
            setDefaultBackTab(obj?.backTab);
        }
        
      }, [data]);

    
    // End code for fetch the data from excel

    useEffect(() => {
        if (history.location.search) {
            let param = history.location.search.substring(1, history.location.search.length);
            let params = param.split('&');
            for (let i=0; i<params.length; i++) {
                let key = params[i].split('=')[0];
                let val = params[i].split('=')[1];

                if (key === 'gclid') setGclid(val);
                else if (key === 'l1') setL1(val);
                else if (key === 'l2') setL2(val);
                else if (key === 'keyword') setKeyword(val);

            }
            
        } else {
            history.push('/');
        }
    }, [ history ]);

    useEffect(() => {
        if (pickupSearchKey.length >= 2) {
            dispatch(handleSearchKayak(pickupSearchKey, autocomplete, geolocation, 'pickup'));
        } else {
            dispatch({
                type: SET_PICKUP_SEARCH_KAYAK_RESULT,
                payload: [],
            });
        }
    // eslint-disable-next-line
    }, [ pickupSearchKey ]);

    useEffect(() => {
        if (dropSearchKey.length >= 2) {
            dispatch(handleSearchKayak(dropSearchKey, autocomplete, geolocation, 'drop'));
        } else {
            dispatch({
                type: SET_DROP_SERACH_KAYAK_RESULT,
                payload: [],
            });
        }
    // eslint-disable-next-line
    }, [ dropSearchKey ]);

    useEffect(() => {
        let tempArr = [];

        if (pickupSearchKey.length >= 2) {
            for (let i=0; i<pickupCities?.length; i++) {
                var tempItem = {};
                tempItem['label'] = pickupCities[i].displayName;
                tempItem['city'] = pickupCities[i].cityName;
                tempItem['country'] = pickupCities[i].country;
                if(pickupCities[i].locType === 'city'){
                    tempItem['icon'] = CityIcon;
                    tempItem['ctid'] = 'c' + pickupCities[i].cityId;
                    tempItem['code'] = pickupCities[i].displayName.replace(" ","") + '-' + tempItem['ctid'];
                } else if (pickupCities[i].locType === 'ap') {
                    tempItem['icon'] = AirportIcon;
                    tempItem['ctid'] = 'a' + pickupCities[i].cityId;
                    tempItem['code'] = pickupCities[i].id + '-' + tempItem['ctid'];
                } else if (pickupCities[i].locType === 'rail') {
                    tempItem['icon'] = TrainIcon;
                    tempItem['ctid'] = 't' + pickupCities[i].cityId;
                    tempItem['code'] = pickupCities[i].id + '-' + tempItem['ctid'];
                } else {
                    console.log("ERROR! New type of object found!", pickupCities[i].locType)
                }
                tempArr.push(tempItem);
            }
        }

        setAutoCompleteDataPickup(tempArr);
    // eslint-disable-next-line
    }, [ pickupCities ]);

    useEffect(() => {
        let tempArr = [];
        if (dropSearchKey.length >= 2) {
            for (let i=0; i<dropCities?.length; i++) {
                var tempItem = {};
                tempItem['label'] = dropCities[i].displayName;
                tempItem['city'] = dropCities[i].cityName;
                tempItem['country'] = dropCities[i].country;
                if(dropCities[i].locType === 'city'){
                    tempItem['icon'] = CityIcon;
                    tempItem['ctid'] = 'c' + dropCities[i].cityId;
                    tempItem['code'] = dropCities[i].displayName.replace(" ","") + '-' + tempItem['ctid'];
                } else if (dropCities[i].locType === 'ap') {
                    tempItem['icon'] = AirportIcon;
                    tempItem['ctid'] = 'a' + dropCities[i].cityId;
                    tempItem['code'] = dropCities[i].id + '-' + tempItem['ctid'];
                } else if (dropCities[i].locType === 'rail') {
                    tempItem['icon'] = AirportIcon;
                    tempItem['ctid'] = 't' + dropCities[i].cityId;
                    tempItem['code'] = dropCities[i].id + '-' + tempItem['ctid'];
                } else {
                    console.log("ERROR! New type of object found!", dropCities[i].locType)
                }
                tempArr.push(tempItem);
            }
        }

        setAutoCompleteDataDrop(tempArr);
    // eslint-disable-next-line
    }, [ dropCities ]);

    useEffect(() => {
        if (pickupDate) {
            setDropDate(getDateString(getOffsetDate(pickupDate, 8)));
        } else {
            setPickupDate(getDateString(getOffsetDate(new Date(), 28)));
        }
    }, [ pickupDate ]);

    useEffect(() => {
        if (dropDate === null) {
            setDropDate(getDateString(getOffsetDate(pickupDate, 8)));
        }
    // eslint-disable-next-line
    }, [ dropDate ]);

    useEffect(() => {
    }, [ pickupTime ]);

    useEffect(() => {
    }, [ dropTime ]);

    const validate = () => {
        if (pickupVal === '' || (showDrop && dropVal === '')) {
            return 0;
        } else if (pickupDate > dropDate) {
            return 1;
        } else if (pickupDate < `${getDateString(new Date())} ${getTimeString(new Date())}`) {
            return 2;
        } else {
            return 100;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        setShowError(true);
        switch (validate()) {
            case 100: {
                let sendData = {
                    gclid: gclid,
                    keyword: keyword,
                    pickup: {
                        searchKey: pickupSearchKey,
                        searchResults: autoCompleteDataPickup.map(ele => ele.label),
                        result: pickupVal,
                    },
                    drop: {
                        searchKey: dropSearchKey,
                        searchResults: autoCompleteDataDrop.map(ele => ele.label),
                        result: dropVal,
                    },
                }
                let redirectData = {
                    l1: l1,
                    l2: l2, 
                    kayakPickupVal: pickupCode,
                    kayakDropVal: dropCode,
                    travelPickupVal: pickupCtid !== '' ? `${pickupCity.replaceAll(' ', '-')},${pickupCountry.replaceAll(' ', '-')}-c${pickupCtid.substring(1)}` : `${pickupCity.replaceAll(' ', '-')},${pickupCountry.replaceAll(' ', '-')}`,
                    pickupDate: `${pickupDate}-${getTime(pickupTime)}h`,
                    dropDate: `${dropDate}-${getTime(dropTime)}h`,
                }
                if (showDrop) {
                    redirectData.travelDropVal = dropCtid !== '' ? `${dropCity.replaceAll(' ', '-')},${dropCountry.replaceAll(' ', '-')}-c${dropCtid.substring(1)}` : `${dropCity.replaceAll(' ', '-')},${dropCountry.replaceAll(' ', '-')}`;
                } else {
                    redirectData.travelDropVal = redirectData.travelPickupVal;
                }
                dispatch(saveLog({ sendData, redirectData, lang, autocomplete, geolocation, webSiteTextContent, hourView, mapView, siteContentLanguage, defaultFrontTab, defaultBackTab, frontTab, backTab }));
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'SearchComplete'
                });
                return;
            }
            case 0: {
                toast.warn('Please fill all required fields with valid values.');
                return;
            }
            case 1: {
                toast.warn('Pick up date cannot be bigger than drop down date.');
                return;
            }
            case 2: {
                toast.warn('Pick up date cannot be sooner than current time.');
                return;
            }
            default: {
                return;
            }
        }
    }

 

    const changeLanguage = e => { 
        setLang(e.target.value);
    }

    const changeLanguage1 = e => { 
        setAutocomplete(e.target.value);
    }

    const changeLanguage2 = e => { 
        setGeo(e.target.value);
    }

    const changeLanguage3 = e => { 
        setWtc(e.target.value);
    }

    const changeLanguageHv = e => { 
        setHv(e.target.value);
    }

    const changeLanguageMap = e => { 
        setMap(e.target.value);
    }

    const changeLanguageScl = e => { 
        setScl(e.target.value);
    }
    

    

    return (
        <Container>
            <Row className="position-relative justify-content-center">
                <Col lg="10" md="10">
                    {/* <Card className="bg-gradient-secondary shadow"> */}
                    <Card className={hourView == 1 ? 'bg-gradient-secondary shadow' : 'bg-gradient-secondary shadow hourview'}>
                        <CardBody className={siteContentLanguage == "ar" || siteContentLanguage == "he" ? "p-lg-5 color-black text-right" : "p-lg-5 color-black"}>
                            
                            {siteContentLanguage == "es" ? 
                            <>
                            <h1 className="mb-1 font-weight-800">Buscar, comparar y alquiler de coches en todo el mundo.</h1>
                            </>
                            : siteContentLanguage == "ar" ? 
                            <>
                            <h1 className="mb-1 font-weight-800">حجز ومقارنة و إيجار سيارات عالميا</h1>
                            </>
                            : siteContentLanguage == "he" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">חפש, השווה והשכר רכבים בכל העולם</h1>
                            </>
                            : siteContentLanguage == "fr" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">Rechercher, comparer et louer des voitures dans le monde entier</h1>
                            </>
                            : siteContentLanguage == "it" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">Cerca, confronta e noleggia auto in tutto il mondo</h1>
                            </>
                            : siteContentLanguage == "cass" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">Buscar, Comparar y Alquilar Coches en Todo el Mundo</h1>
                            </>
                            : siteContentLanguage == "de" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">Autos mieten und vergleichen</h1>
                            </>
                            : siteContentLanguage == "my" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">Sewa kereta di seluruh dunia</h1>
                            </>
                            : siteContentLanguage == "mx" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">Buscar, Comparar y Rentar Carros en Todo el Mundo</h1>
                            </>
                            : siteContentLanguage == "zh" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">搜索、比较和租借全球各地的汽车</h1>
                            </>
                            : siteContentLanguage == "th" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">ค้นหา เปรียบเทียบ และเช่ารถทั่วโลก</h1>
                            </>
                            : siteContentLanguage == "id" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">Cari, bandingkan, dan sewa mobil di seluruh dunia</h1>
                            </>
                            : siteContentLanguage == "vi" ? 
                            <>
                             <h1 className="mb-1 font-weight-800">Tìm kiếm, so sánh và thuê xe ô tô trên toàn thế giới</h1>
                            </>
                            : siteContentLanguage == "zh-sg" ?
                            <>
                             <h1 className="mb-1 font-weight-800">搜尋、比較和租借全球各地的汽車</h1>
                            </>
                            : siteContentLanguage == "zh-hk" ?
                            <>
                             <h1 className="mb-1 font-weight-800">全球搜尋、比較和租車</h1>
                            </>
                            :<><h1>Search, Compare & Rent Cars Worldwide</h1></>
        }
                            <p className="mt-0 para">
                                {siteContentLanguage == "es" ? "Descubrir nuevas ofertas de alquiler de coches y ahorrar hasta un 60%." 
                                : siteContentLanguage == "ar" ? "اكتشف عروض تأجير السيارات الجديدة ووفر ما يصل إلى 60٪."
                                : siteContentLanguage == "he" ? "גלה אפשרויות חדשות להשכרת רכב וחסוך עד 60%"
                                : siteContentLanguage == "fr" ? "Découvrez de nouvelles offres de location de voitures et économisez jusqu'à 60%."
                                : siteContentLanguage == "it" ? "Scopri nuove offerte di autonoleggio e risparmia fino al 60%."
                                : siteContentLanguage == "cass" ? "Descubre nuevas ofertas de alquiler de coches y ahorra hasta un 60%."
                                : siteContentLanguage == "de" ? "Finden Sie neue Angebote für Mietwagen und sparen Sie bis zu 60 %."
                                : siteContentLanguage == "my" ? "Temui tawaran sewa kereta baharu dan jimatkan sehingga 60%."
                                : siteContentLanguage == "mx" ? "Descubre nuevas ofertas de renta de carros y ahorra hasta un 60%."
                                : siteContentLanguage == "zh" ? "发现新的租车优惠并节省高达 60%。"
                                : siteContentLanguage == "th" ? "ค้นพบข้อเสนอใหม่สำหรับการเช่ารถและประหยัดได้สูงสุด 60%"
                                : siteContentLanguage == "id" ? "Temukan penawaran baru untuk sewa mobil dan hemat hingga 60%."
                                : siteContentLanguage == "vi" ? "Khám phá các giao dịch cho thuê xe mới và tiết kiệm tới 60%."
                                : siteContentLanguage == "zh-sg" ? "探索新的租車優惠並節省高達 60%。"
                                : siteContentLanguage == "zh-hk" ? "探索新的租車優惠，節省高達 60%。"
                                : 
                                "Discover new rental car deals and save up to 60%."}
                                
                            </p>
                            <FormGroup className="mt-4">
                                <label>{siteContentLanguage == "es" ? "Ubicación de partida" 
                                : siteContentLanguage == "ar" ? "مكان الاستلام"
                                : siteContentLanguage == "he" ? "מיקום איסוף"
                                : siteContentLanguage == "fr" ? "Lieu de prise en charge"
                                : siteContentLanguage == "it" ? "Luogo di ritiro"
                                : siteContentLanguage == "cass" ? "Lugar de recogida"
                                : siteContentLanguage == "de" ? "Abholort"
                                : siteContentLanguage == "my" ? "Tempat pengambilan"
                                : siteContentLanguage == "mx" ? "Lugar de recogida"
                                : siteContentLanguage == "zh" ? "取车地点"
                                : siteContentLanguage == "th" ? "จุดรับรถ"
                                : siteContentLanguage == "id" ? "Lokasi pengambilan"
                                : siteContentLanguage == "vi" ? "Địa điểm nhận xe"
                                : siteContentLanguage == "zh-sg" ? "取車地點"
                                : siteContentLanguage == "zh-hk" ? "取車地點"
                                : 
                                "Pick up location"}</label>
                                <InputGroup className={`input-group-alternative ${showError && pickupVal === '' ? 'error-rt' : ''}`}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-user-run" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <AutoCompleteCustom items={autoCompleteDataPickup} value={pickupVal} setValue={setPickupVal}
                                        setSearchKey={setPickupSearchKey} setCity={setPickupCity} setCode={setPickupCode}
                                        setCtid={setPickupCtid} setCountry={setPickupCountry} setAutocomplete={autocomplete} setGeolocation={geolocation} setSiteContentLanguage={siteContentLanguage}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup className="mt-2 d-flex">
                                <label className="custom-toggle">
                                    <input type="checkbox" onChange={handleDropToggle} />
                                    <span className="custom-toggle-slider rounded-circle" />
                                </label>
                                <label className="ml-2">
                                {siteContentLanguage == "es" ? "Regresar a una ubicación diferente" 
                                : siteContentLanguage == "ar" ? "العودة إلى موقع مختلف"
                                : siteContentLanguage == "he" ? "החזר למיקום אחר"
                                : siteContentLanguage == "fr" ? "Retour à un autre emplacement"
                                : siteContentLanguage == "it" ? "Ritorna a una posizione diversa"
                                : siteContentLanguage == "cass" ? "Volver a una ubicación diferente"
                                : siteContentLanguage == "de" ? "Rückgabe an einem anderen Ort"
                                : siteContentLanguage == "my" ? "Kembali ke lokasi berbeza"
                                : siteContentLanguage == "mx" ? "Regresar a una ubicación diferente"
                                : siteContentLanguage == "zh" ? "返回不同地点"
                                : siteContentLanguage == "th" ? "กลับไปยังตำแหน่งอื่น"
                                : siteContentLanguage == "id" ? "Kembali ke lokasi lain"
                                : siteContentLanguage == "vi" ? "Trở về một địa điểm khác"
                                : siteContentLanguage == "zh-sg" ? "返回不同地點"
                                : siteContentLanguage == "zh-hk" ? "返回不同地點"
                                : 
                                "Return to a different location"
                                }
                                    </label>
                            </FormGroup>
                            { showDrop &&
                            <FormGroup className="mt-2">
                                <label>
                                {siteContentLanguage == "es" ? "Fecha de devolucióna" 
                                : siteContentLanguage == "ar" ? "تاريخ التسليم"
                                : siteContentLanguage == "he" ? "תאריך החזרה"
                                : siteContentLanguage == "fr" ? "Date de retour"
                                : siteContentLanguage == "it" ? "Data di riconsegna"
                                : siteContentLanguage == "cass" ? "Fecha de devolución"
                                : siteContentLanguage == "de" ? "Rückgabedatum"
                                : siteContentLanguage == "my" ? "Tarikh hantar"
                                : siteContentLanguage == "mx" ? "Fecha de devolución"
                                : siteContentLanguage == "zh" ? "还车日期"
                                : siteContentLanguage == "th" ? "วันที่ส่งคืนรถ"
                                : siteContentLanguage == "id" ? "Tanggal pengembalian"
                                : siteContentLanguage == "vi" ? "Ngày trả xe"
                                : siteContentLanguage == "zh-sg" ? "還車日期"
                                : siteContentLanguage == "zh-hk" ? "還車日期"
                                
                                : 
                                "Drop off location"
                                }
                                </label>
                                <InputGroup className={`input-group-alternative ${showError && dropVal === '' ? 'error-rt' : ''}`}>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-user-run" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <AutoCompleteCustom items={autoCompleteDataDrop} value={dropVal} setValue={setDropVal}
                                        setSearchKey={setDropSearchKey} setCity={setDropCity} setCode={setDropCode}
                                        setCtid={setDropCtid} setCountry={setDropCountry}  setAutocomplete={autocomplete} setGeolocation={geolocation} setSiteContentLanguage={siteContentLanguage}
                                    />
                                </InputGroup>
                            </FormGroup>
                            }
                            <FormGroup>
                                <label>
                                {siteContentLanguage == "es" ? "Fecha de partida" 
                                : siteContentLanguage == "ar" ? "تاريخ الاستلام"
                                : siteContentLanguage == "he" ? "תאריך איסוף"
                                : siteContentLanguage == "fr" ? "Date de prise en charge"
                                : siteContentLanguage == "it" ? "Data di ritiro"
                                : siteContentLanguage == "cass" ? "Fecha de recogida"
                                : siteContentLanguage == "de" ? "Abholdatum"
                                : siteContentLanguage == "my" ? "Tarikh pengambilan"
                                : siteContentLanguage == "mx" ? "Fecha de recogida"
                                : siteContentLanguage == "zh" ? "取车日期"
                                : siteContentLanguage == "th" ? "วันที่รับรถ"
                                : siteContentLanguage == "id" ? "Tanggal pengambilan"
                                : siteContentLanguage == "vi" ? "Ngày nhận xe"
                                : siteContentLanguage == "zh-sg" ? "取車日期"
                                : siteContentLanguage == "zh-hk" ? "取車日期"
                                : 
                                "Pick up date"
                                }
                                    </label>
                                <div className={hourView == 1 ? 'd-flex' : 'd-flex hourView'}>
                                    <InputGroup className={`input-group-alternative mr-2 ${showError && (pickupDate === '' || pickupDate === null) ? 'error-rt' : ''}`}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Choose your pick up date"
                                            }}
                                            timeFormat={false}
                                            onChange={e => setPickupDate(getDateString(e._d))}
                                            closeOnSelect
                                            value={new Date(pickupDate)}
                                            viewDate={new Date(pickupDate)}
                                            isValidDate={currentDate => {
                                                if (currentDate._d < new Date()) return false;
                                                return true;
                                            }}
                                        />
                                    </InputGroup>
                                    {hourView != 0 ?
                                    <InputGroup className={`input-group-alternative ${showError && pickupTime === '' ? 'error-rt' : ''}`}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-compass-04" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <AutoCompleteCustom items={times} value={pickupTime} setValue={setPickupTime} />
                                    </InputGroup>
                                    : "" }
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <label>
                                {siteContentLanguage == "es" ? "fecha de entrega" 
                                : siteContentLanguage == "ar" ? "تاريخ التسليم"
                                : siteContentLanguage == "he" ? "תאריך החזרה"
                                : siteContentLanguage == "fr" ? "Date de retour"
                                : siteContentLanguage == "it" ? "Data di riconsegna"
                                : siteContentLanguage == "cass" ? "Fecha de devolución"
                                : siteContentLanguage == "de" ? "Rückgabedatum"
                                : siteContentLanguage == "my" ? "Tarikh hantar"
                                : siteContentLanguage == "mx" ? "Fecha de devolución"
                                : siteContentLanguage == "zh" ? "还车日期"
                                : siteContentLanguage == "zh-sg" ? ""
                                : siteContentLanguage == "th" ? "วันที่ส่งคืนรถ"
                                : siteContentLanguage == "id" ? "Tanggal pengembalian"
                                : siteContentLanguage == "vi" ? "Ngày trả xe"
                                : siteContentLanguage == "zh-sg" ? "還車日期"
                                : siteContentLanguage == "zh-hk" ? "還車日期"
                                : 
                                "Drop date"
                                }</label>
                                <div className={hourView == 1 ? 'd-flex' : 'd-flex hourView'}>
                                    <InputGroup className={`input-group-alternative mr-2 ${showError && (dropDate === '' || dropDate === null) ? 'error-rt' : ''}`}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            inputProps={{
                                                placeholder: "Choose your drop date"
                                            }}
                                            timeFormat={false}
                                            onChange={e => setDropDate(getDateString(e._d))}
                                            closeOnSelect
                                            value={new Date(dropDate)}
                                            viewDate={new Date(dropDate)}
                                            isValidDate={currentDate => {
                                                if (currentDate._d < new Date(pickupDate)) return false;
                                                return true;
                                            }}
                                        />
                                    </InputGroup>
                                    {hourView != 0 ?
                                    <InputGroup className={`input-group-alternative ${showError && dropTime === '' ? 'error-rt' : ''}`}>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-compass-04" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <AutoCompleteCustom items={times} value={dropTime} setValue={setDropTime} />
                                    </InputGroup>
                                    : ""}
                                </div>
                            </FormGroup>
                            <div>
                                <Button
                                    block
                                    className="btn-round"
                                    color="default"
                                    size="lg"
                                    type="button"
                                    onClick={handleSubmit}
                                >
                                    {siteContentLanguage == "es" ? "BUSCAR" 
                                    : siteContentLanguage == "ar" ? "بحث"
                                    : siteContentLanguage == "he" ? "חיפוש"
                                    : siteContentLanguage == "fr" ? "RECHERCHER"
                                    : siteContentLanguage == "it" ? "CERCA"
                                    : siteContentLanguage == "cass" ? "BUSCAR"
                                    : siteContentLanguage == "de" ? "SUCHEN"
                                    : siteContentLanguage == "my" ? "CARI"
                                    : siteContentLanguage == "mx" ? "BUSCAR"
                                    : siteContentLanguage == "zh" ? "搜索"
                                    : siteContentLanguage == "th" ? "ค้นหา"
                                    : siteContentLanguage == "id" ? "Cari"
                                    : siteContentLanguage == "vi" ? "Tìm kiếm"
                                    : siteContentLanguage == "zh-sg" ? "搜索"
                                    : siteContentLanguage == "zh-hk" ? "搜索"
                                    : 
                                        "Search"}
                                    
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default SearchForm;
